import React, { useState } from 'react';

import { Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { LabelText } from './TextComponents';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@admin/styles/theme';

const { Option, OptGroup } = Select;

const SelectWrapper = styled.div`
  margin: unset;
  width: 100%;

  .ant-select-clear {
    top: 45%;
  }

  .ant-select-multiple {
    .ant-select-selector {
      padding: 3px 30px 3px 5px !important;
      gap: 5px;
      .ant-select-selection-item {
        padding: 0 10px;
        height: 32px;
        margin: 0;
        align-items: center;
        .ant-select-selection-item-remove {
          margin: -7px 0 0 5px;
          font-size: 14px;
          color: ${theme.colors.blue30};
        }
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  &&& {
    width: 100%;
    min-width: 240px;

    > div {
      padding: 4px 11px;
      min-height: 42px;
      border-color: ${theme.colors.gray};
    }

    color: ${theme.colors.black};
    font-size: 1rem;
    font-weight: 400;

    .ant-select-selection-placeholder {
      color: ${theme.colors.blackL44};
    }

    svg {
      color: ${theme.colors.blue60};
    }

    &:hover {
      border-color: ${theme.colors.teal};

      svg {
        color: ${({ disabled }) => (disabled ? theme.colors.blue60 : theme.colors.blue)};
      }
    }
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ $font }) => $font ?? 'unset'};
  svg {
    margin-right: 0.5rem;
  }
`;

const ImpactiveSelect = ({
  label,
  value,
  options,
  groupedOptions,
  onChange,
  name,
  placeholder,
  mode,
  wrapperStyle,
  tooltip,
  allowClear,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const renderOptions = (item, index) => (
    <Option
      key={`${index}${item.label}`}
      value={item.value}
      disabled={item.disabled}
      label={item.label}
    >
      <OptionContent $font={item.font}>
        {item.icon}
        {item.label}
      </OptionContent>
    </Option>
  );

  return (
    <SelectWrapper style={wrapperStyle}>
      {label && <LabelText tooltipData={tooltip}>{label}</LabelText>}
      <StyledSelect
        name={name}
        placeholder={placeholder}
        value={mode === 'multiple' && !value ? [] : value}
        open={visible}
        showArrow
        onChange={onChange}
        onDropdownVisibleChange={setVisible}
        mode={mode}
        allowClear={allowClear}
        menuItemSelectedIcon={
          <CheckOutlined style={{ color: theme.colors.blue, transform: 'translateY(-3px)' }} />
        }
        {...rest}
      >
        {groupedOptions
          ? groupedOptions.map((item, index) => (
              <OptGroup key={index} label={item.label}>
                {item.options.map(renderOptions)}
              </OptGroup>
            ))
          : options?.map(renderOptions)}
      </StyledSelect>
    </SelectWrapper>
  );
};

const optionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
);

ImpactiveSelect.propTypes = {
  allowClear: PropTypes.bool,
  groupedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: optionsProps,
    }),
  ),
  label: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: optionsProps,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,

  value: PropTypes.any,
  wrapperStyle: PropTypes.object,
};

ImpactiveSelect.defaultProps = { allowClear: true, placeholder: 'Select ...', wrapperStyle: null };

export default ImpactiveSelect;
