import React from 'react';

import HelpTooltip from '@admin/components/helpers/HelpTooltip';
import { Flex } from '@admin/components/helpers/LayoutComponents';

import styled from 'styled-components';
import pxToRem from '@admin/utils/pxToRem';
import theme from '@admin/styles/theme';
import PropTypes from 'prop-types';

const color = theme.colors.black;

const PageTitle = styled.h1`
  color: ${color};
  font-weight: 500;
  font-size: ${pxToRem(40)};
  line-height: ${pxToRem(47)};
`;

const BlockTitle = styled.h2`
  color: ${color};
  font-weight: 500;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(27)};
`;

const SectionTitle = styled.h3`
  color: ${color};
  font-weight: 500;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(22)};
`;

const Cardtitle = styled.h5`
  color: ${color};
  font-weight: 700;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(16)};
  margin: 0;
`;

const PrimaryText = styled.div`
  color: ${color};
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
`;

const SecondaryText = styled.div`
  color: ${color};
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(16)};
`;

const TipsText = styled.div`
  color: ${color};
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(13)};
`;

const StyledLabel = styled.label`
  color: ${theme.colors.black};
  margin-bottom: 0;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(19)};
`;

const Required = styled.span`
  color: red;
  margin-left: 3px;
`;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  background: ${theme.colors.teal20};
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelText = ({ tooltipData, children, isRequired, index, ...rest }) => (
  <Flex style={{ marginBottom: '0.5rem' }} gap="5px">
    {index && <Circle>{index}</Circle>}
    <StyledLabel {...rest}>
      {children}
      {isRequired && <Required>*</Required>}
    </StyledLabel>
    {tooltipData && <HelpTooltip text={tooltipData} />}
  </Flex>
);

LabelText.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  isRequired: PropTypes.bool,
  tooltipData: PropTypes.string,
};

export {
  PageTitle,
  BlockTitle,
  SectionTitle,
  Cardtitle,
  PrimaryText,
  SecondaryText,
  StyledLabel,
  TipsText,
  LabelText,
};
