import React from 'react';

import { Tooltip } from 'antd';

import styled from 'styled-components';
import theme from '@admin/styles/theme';
import { string } from 'prop-types';

const Content = styled(Tooltip)`
  &&& {
    background: rgba(35, 41, 62, 0.06);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 3px;
    font-size: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    color: ${theme.colors.blackL42};

    &:hover {
      color: ${theme.colors.black};
      border: 1px solid ${theme.colors.blue30};
    }
  }
`;

const HelpTooltip = props => {
  const { placement, text } = props;
  return (
    <Tooltip
      overlayStyle={{ maxWidth: 400 }}
      placement={placement || 'right'}
      title={text}
      trigger={'hover'}
      {...props}
    >
      <Content>
        <i className="fas fa-question " />
      </Content>
    </Tooltip>
  );
};

HelpTooltip.propTypes = {
  placement: string,
  text: string,
};

export default HelpTooltip;
