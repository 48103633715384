import { getDefaultInitialScript } from './ActivityForm/useInitialValues';

import theme from '@admin/styles/theme';

const activityTypes = [
  'DialerActivity',
  'TextAFriendActivity',
  'TextAListActivity',
  'IdVotersActivity',
  'AssignedCanvassingActivity',
  'TestimonialActivity',
  'ShareActivity',
  'WebActivity',
  'RegistrationActivity',
  'ReportActivity',
  'BroadcastActivity',
];

const reassignableActivityTypes = [
  'AssignedCanvassingActivity',
  'TextAListActivity',
  'UserBroadcastActivity',
  'BroadcastActivity',
];

const activityFilterOptions = showVideoActivity =>
  [
    {
      label: 'Phone Bank',
      value: 'DialerActivity',
    },
    {
      label: 'Peer to Peer',
      value: 'TextAListActivity',
    },
    {
      label: 'Friend To Friend',
      value: 'TextAFriendActivity',
    },
    {
      label: 'Canvassing',
      value: 'Canvassing',
    },
    {
      label: 'Social Sharing',
      value: 'ShareActivity',
    },
    showVideoActivity && {
      label: 'Video Stories',
      value: 'TestimonialActivity',
    },
    {
      label: 'Forms',
      value: 'SurveyActivity',
    },
    {
      label: 'Open a Webpage',
      value: 'WebActivity',
    },
    {
      label: 'Voter Registration',
      value: 'RegistrationActivity',
    },
  ].filter(Boolean);

const messages = {
  ERROR_CREATE: 'There was a problem creating your action.',
  ERROR_UPDATE: 'There was a problem saving your action.',
  SUCCESS_CREATE: 'Your action was created successfully.',
  SUCCESS_UPDATE: 'Your action was saved successfully.',
  UNAUTHORIZED_ERROR: 'You are not authorized to perform that action.',
};

const unfilterableActivityTypes = [
  'DialerActivity' /* not a live action */,
  'RegistrationActivity' /* outdated */,
  'ReportActivity' /* not technically an action */,
];

const getActivityMenuData = (campaignFeatures, showVideoActivity) => {
  return [
    {
      disabled: !campaignFeatures.p2p_enabled,
      name: 'Peer to Peer',
      type: 'TextAListActivity',
      urlData: 'text_banks',
    },
    {
      name: 'Friend to Friend',
      type: 'TextAFriendActivity',
      urlData: 'friend_banks',
    },
    {
      name: 'Form',
      type: 'SurveyActivity',
      urlData: 'build_list',
    },
    {
      disabled: !campaignFeatures.dialer_enabled,
      name: 'Regular Phone Bank',
      type: 'DialerActivity',
      urlData: 'phone_banks',
    },
    {
      disabled: !campaignFeatures.dialer_enabled,
      name: 'Patch-Through Phone Bank',
      params: '?isPatchThrough=true',
      type: 'DialerActivity',
      urlData: 'phone_banks',
    },
    {
      disabled: !campaignFeatures.list_canvassing_enabled,
      name: 'Shared List Canvassing',
      type: 'IdVotersActivity',
      urlData: 'shared_list_canvassing',
    },
    {
      disabled: !campaignFeatures.list_canvassing_enabled,
      name: 'Assigned List Canvassing',
      type: 'AssignedCanvassingActivity',
      urlData: 'assigned_list_canvassing',
    },
    {
      name: 'Social Sharing',
      type: 'ShareActivity',
      urlData: 'social_sharing',
    },
    showVideoActivity && {
      name: 'Video Story',
      type: 'TestimonialActivity',
      urlData: 'stories',
    },
    {
      name: 'Open a Webpage',
      type: 'WebActivity',
      urlData: 'webpage',
    },
    {
      name: 'Voter Registration',
      type: 'RegistrationActivity',
      urlData: 'registration_activities',
    },
  ].filter(Boolean);
};

function friendlyTypeNameFor(type, plural = true) {
  switch (type) {
    case 'DialerActivity':
    case 'phone_banks':
      return 'Phone Bank';

    case 'RegistrationActivity':
      return 'Voter Registration';

    case 'TestimonialActivity':
    case 'stories':
      return plural ? 'Video Stories' : 'Video Story';

    case 'TextAFriendActivity':
    case 'friend_banks':
      return 'Friend to Friend';

    case 'TextAListActivity':
    case 'text_banks':
      return 'Peer to Peer';

    case 'ShareActivity':
    case 'social_sharing':
      return 'Social Sharing';

    case 'FormActivity':
    case 'surveys':
      return plural ? 'Surveys' : 'Survey';

    case 'SurveyActivity':
    case 'build_list':
      return 'Forms';

    case 'WebActivity':
    case 'webpage':
      return 'Open a Webpage';

    case 'NewsActivity':
      return 'Make an Announcement';

    case 'shared_list_canvassing':
    case 'assigned_list_canvassing':
    case 'Canvassing':
      return 'Canvassing';

    case 'IdVotersActivity':
    case 'ReportActivity':
      return 'Shared List Canvassing';

    case 'AssignedCanvassingActivity':
      return 'Assigned List Canvassing';

    case 'BroadcastActivity':
    case 'broadcast':
      return 'Send a Broadcast SMS';

    case 'ReminderActivity':
    case 'reminder_activities':
      return 'Push Notification';

    case 'EmailReminderActivity':
    case 'email_reminder_activities':
      return 'Email Reminder';

    case 'DirectMessageActivity':
    case 'direct_message_activities':
      return 'Direct Message';

    default:
      return '';
  }
}

function getActivityUrlForType(activityType) {
  switch (activityType) {
    case 'RegistrationActivity':
      return 'voter_registration';

    case 'TextAListActivity':
      return 'text_banks';

    case 'TextAFriendActivity':
      return 'friend_banks';

    case 'IdVotersActivity':
    case 'ReportActivity':
      return 'shared_list_canvassing';

    case 'AssignedCanvassingActivity':
      return 'assigned_list_canvassing';

    case 'ShareActivity':
      return 'social_sharing';

    case 'TestimonialActivity':
      return 'stories';

    case 'FormActivity':
      return 'surveys';

    case 'SurveyActivity':
      return 'build_list';

    case 'BroadcastActivity':
      return 'broadcast';

    case 'DialerActivity':
      return 'phone_banks';

    case 'WebActivity':
      return 'webpage';

    default:
      return 'all';
  }
}

function getActivityTypeForPage(activityTypeUrl) {
  switch (activityTypeUrl) {
    case 'voter_registration':
      return 'RegistrationActivity';

    case 'broadcast':
      return 'BroadcastActivity';

    case 'phone_banks':
      return 'DialerActivity';

    case 'text_banks':
      return 'TextAListActivity';

    case 'friend_banks':
      return 'TextAFriendActivity';

    case 'canvassing':
      return 'Canvassing';

    case 'shared_list_canvassing':
      return 'IdVotersActivity';

    case 'assigned_list_canvassing':
      return 'AssignedCanvassingActivity';

    case 'social_sharing':
      return 'ShareActivity';

    case 'stories':
      return 'TestimonialActivity';

    case 'surveys':
      return 'FormActivity';

    case 'build_list':
      return 'SurveyActivity';

    case 'webpage':
      return 'WebActivity';

    case 'registration_activities':
      return 'RegistrationActivity';

    default:
      return null;
  }
}

function getTooltipForTypePage(activityType) {
  switch (activityType) {
    case 'DialerActivity':
      return {
        body: 'Select a contact list, write a script, and publish a phone banking action to connect your users with your contacts for high-impact phone calls. Predictive and non-predictive dialing options available. ',
        link: 'https://support.impactive.io/en/articles/9243521-phone-banking-actions-for-admins',
        title: 'Simple, Efficient Phone Banking',
      };

    case 'TextAListActivity':
      return {
        body: 'Select your contact list, write initial and response scripts, and publish a text banking action to connect your users with your contacts in 1:1 text messaging conversations. Volunteers manage replies from their inbox on web or mobile.',
        link: 'https://support.impactive.io/en/articles/9243536-peer-to-peer-actions-for-admins',
        title: 'High-Speed, User-Friendly Peer-to-Peer Texting',
      };

    case 'TextAFriendActivity':
      return {
        body: 'Did you know that contact from a friend or family member is proven to be the most effective way to GOTV, encourage event sign-up, and more? Write scripts for your volunteers to reach out to their personal contacts on behalf of your cause.',
        link: 'https://support.impactive.io/en/articles/9243552-friend-to-friend-messaging',
        title: 'Make an Impact with Trusted Messengers',
      };

    case 'AssignedCanvassingActivity':
    case 'IdVotersActivity':
    case 'Canvassing':
      return {
        body: 'Assign contacts to your volunteers (or let them self-select their assignments) and write scripts for them to conduct personalized outreach to your target contacts.',
        link: 'https://support.impactive.io/en/collections/10145828-canvassing',
        title: 'Forge Genuine Relationships With Your Target Audience',
      };

    case 'ShareActivity':
      return {
        body: 'Share content (text, images, URLs) with your volunteers so they can amplify it on their social media channels, building buzz around your cause.',
        link: 'https://support.impactive.io/en/articles/9243586-social-share-overview',
        title: 'Amplify Your Messaging Online',
      };

    case 'SurveyActivity':
      return {
        body: 'Forms can be customized for many use cases (subscriber opt-in, volunteer sign-up, surveys, event RSVP, etc.) and gather contact data to grow your list in Impactive. You can also encourage respondents to share forms with their friends and family, unlocking many relational capabilities like paid relational, sweepstakes, and more.',
        link: 'https://support.impactive.io/en/articles/9243373-app-less-relational-relational-organizing-on-impactive-via-sms-and-trackable-links',
        title: 'Powerful, Customizable Forms with Relational Capabilities',
      };

    case 'WebActivity':
      return {
        body: 'Promote a URL for volunteers to visit within their Impactive action feed for event sign-up, petition signing, or other calls to action.',
        link: 'https://support.impactive.io/en/articles/9243596-open-a-webpage-overview',
        title: 'Send Volunteers to a Webpage',
      };

    case 'RegistrationActivity':
      return {
        body: 'Customize a portal to encourage your audience to check their voter registration. You can also encourage respondents to share the voter registration check campaign with their friends and family, unlocking many relational capabilities like paid relational, sweepstakes, and more.',
        link: 'https://support.impactive.io/en/articles/9243574-setting-up-voter-registration',
        title: 'Custom Voter Registration Check Portals with Relational Capabilities',
      };

    case 'TestimonialActivity':
      return null;

    default:
      return {
        body: 'Publish actions that volunteers can participate in, like Peer-to-Peer Texting, Phone Banking, and Friend to Friend Messaging (and more!), or activate forms or voter registration campaigns.',
        link: 'https://support.impactive.io/en/articles/9243504-basics-of-actions-on-impactive',
        title: 'Multiple Action Types, All In One Tool',
      };
  }
}

function isDisabled(type) {
  return type === 'IdVotersActivity' || type === 'EventsActivity';
}

const isInitialScript = element => {
  return element.script_type === 'initial';
};

function isTextingActivity(type) {
  return (
    type === 'TextAFriendActivity' ||
    type === 'TextAListActivity' ||
    type === 'AssignedCanvassingActivity' ||
    type === 'IdVotersActivity'
  );
}

function isRegistrationActivity(type) {
  return type === 'RegistrationActivity' || type === 'registration_activities';
}

function isActivityClosed(state) {
  return state === 'closing' || state === 'closed';
}

function isActivityDeleted(state) {
  return state === 'deleted';
}

function isActivityCloseable(activity) {
  if (activity) {
    const { aasm_state: state } = activity;
    return !(isActivityClosed(state) || isActivityDeleted(state));
  }
  return false;
}

function isActivityReopenable(activity) {
  return !activity ? false : isActivityClosed(activity.aasm_state);
}

function isActivityDeletable(activity) {
  if (activity) {
    const { aasm_state: state } = activity;
    return !isActivityDeleted(state);
  }
  return false;
}

function isActivityPublic(privacy_setting) {
  return privacy_setting === 'public';
}

function isActivityPublished(activity) {
  if (activity) {
    return activity.aasm_state === 'publishing' || activity.aasm_state === 'published';
  }
  return false;
}

function isActivityPublicizable(activity) {
  if (activity) {
    const { privacy_setting, aasm_state: state } = activity;
    return (
      !isActivityClosed(state) &&
      (!isActivityPublished(activity) || !isActivityPublic(privacy_setting))
    );
  }
  return false;
}

function statusColorFor(state) {
  switch (state) {
    case 'draft':
      return theme.colors.coral40;
    case 'scheduled':
      return '#b1bde2';
    case 'publishing':
    case 'published':
      return theme.colors.teal40;
    case 'closing':
    case 'closed':
      return '#212121';
    case 'deleted':
      return '#b71c1c';
    default:
      return '#f3786c'; //todo: user coral var
  }
}

function privacyColorFor(state) {
  switch (state) {
    case 'private':
      return theme.colors.blue16;
    case 'public':
      return '#E9F0FF'; //todo: use teal var
    default:
      return '#212121';
  }
}

function statusFor(state) {
  switch (state) {
    case 'draft':
      return 'Draft';
    case 'scheduled':
      return 'Scheduled';
    case 'publishing':
    case 'published':
      return 'Published';
    case 'closing':
    case 'closed':
      return 'Closed';
    case 'deleted':
      return 'Deleted';
    default:
      return 'Error';
  }
}

const shouldRemoveActivityScripts = (type, modes_permitted) => {
  if (type === 'IdVotersActivity' && (!modes_permitted || modes_permitted.length === 0)) {
    return true;
  }

  if (!isTextingActivity(type)) {
    return true;
  }

  return false;
};

const formatIncentives = (incentives = [], incentivesToDestroy = []) => {
  const incentivesAttributes = incentives.concat(incentivesToDestroy).map(s => {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    delete s._type;
    delete s.image_processing;
    return s;
  });

  return incentivesAttributes;
};

const permitActivityParams = (values, incentivesToDestroy) => {
  const submitValues = values;

  if (shouldRemoveActivityScripts(submitValues.type, submitValues.modes_permitted)) {
    // Remove activity scripts if the activity type is not texting
    delete submitValues.activity_scripts_attributes;
  } else {
    // Remove unpermitted params for each script
    const scripts = submitValues.activity_scripts_attributes.map(s => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      delete s._type;
      delete s.index;

      if (!isRegistrationActivity(submitValues.type)) {
        delete s.keyword;
      }

      return s;
    });
    submitValues.activity_scripts_attributes = scripts;
  }

  if (!isRegistrationActivity(submitValues.type) && submitValues.type !== 'TextAFriendActivity') {
    delete submitValues.activity_incentives_attributes;
    delete submitValues.automated_texting_enabled;
  } else {
    submitValues.activity_incentives_attributes = formatIncentives(
      submitValues.activity_incentives_attributes,
      incentivesToDestroy,
    );

    // Remove unpermitted attrs from activity user lists too
    submitValues.activity_user_lists_attributes = submitValues?.activity_user_lists_attributes?.map(
      ul => {
        delete ul._type;
        delete ul.name;
        return ul;
      },
    );
  }

  // Remove unpermitted params from the activity
  // eslint-disable-next-line no-underscore-dangle
  const unpermittedParams = [
    '_type',
    'aasm_state',
    'published_at',
    'likes',
    'created_at',
    'updated_at',
    'campaign',
    'impressions',
    'seen',
    'clicks',
    'started',
    'completions',
    'performs',
    'contact_list',
    'is_onboarding_activity',
    'is_default_f2f_activity',
    'follow_up_questions_and_answers',
    'summary_metrics',
    'app_link',
    'web_link',
  ];
  unpermittedParams.forEach(k => delete submitValues[k]);

  return submitValues;
};

const getPercentage = (num, denom, cap = null) => {
  if (!num || !denom) return 0;
  let pct_val = (num / denom) * 100;

  if (cap !== null) {
    pct_val = pct_val > cap ? cap : pct_val;
  }

  return Number(pct_val).toFixed(1);
};

const formatNumber = value => {
  if (!value) return 0;
  if (value) return Number(value).toFixed(0);
};

// intentionally commenting out types we want to exclude
const ACTIVITY_TYPES_WE_WANT_TO_SHOW = [
  // 'BroadcastActivity',
  'DialerActivity',
  'DirectMessageActivity',
  'EmailReminderActivity',
  'FormActivity',
  'GaRegistrationActivity',
  'IdVotersActivity',
  'AssignedCanvassingActivity',
  // 'NewsActivity',
  'RegistrationActivity',
  'ReminderActivity',
  'ReportActivity',
  'ShareActivity',
  'TestimonialActivity',
  'TextAFriendActivity',
  'TextAListActivity',
  // 'UserBroadcastActivity',
  'WebActivity',
];

const changeOpenCanvassingScriptsState = (showScripts, values, setFieldValue, campaignId) => {
  if (showScripts) {
    const modes = new Set([0]);
    values.activity_scripts_attributes.forEach(script => {
      switch (script.mode) {
        case 'email': {
          modes.add(1);
          break;
        }
        case 'phone': {
          modes.add(2);

          break;
        }
        case 'social': {
          modes.add(3);
          break;
        }
        default: {
        }
      }
    });

    setFieldValue('modes_permitted', [...modes]);

    /**
     * After a canvassing activity has been saved with no outreach,
     * Its initial scripts array is set to [].
     * When editing such action, when changing to "with outreach" we need to ensure that a required default texting script is present.
     */
    if (
      values.activity_scripts_attributes?.filter(
        script => script.mode === 'sms' && script.script_type === 'initial',
      ).length < 1
    ) {
      const defaultInitialScript = getDefaultInitialScript(campaignId);
      setFieldValue('activity_scripts_attributes', [
        ...values.activity_scripts_attributes,
        defaultInitialScript,
      ]);
    }
  } else {
    setFieldValue('modes_permitted', []);
  }
};

const activityStatuses = {
  closed: 'Closed',
  deleted: 'Deleted',
  draft: 'Draft',
  published: 'Published',
  scheduled: 'Scheduled',
};

const f2fModesPermitted = values => {
  const modes = new Set([0]);
  values.activity_scripts_attributes.forEach(script => {
    switch (script.mode) {
      case 'sms': {
        modes.add(0);
        break;
      }
      case 'phone': {
        modes.add(1);

        break;
      }
      case 'email': {
        modes.add(2);

        break;
      }
      case 'social': {
        modes.add(3);
        break;
      }
      case 'postcard': {
        modes.add(4);
        break;
      }
      default: {
      }
    }
  });
  return [...modes];
};

export {
  activityTypes,
  activityFilterOptions,
  changeOpenCanvassingScriptsState,
  unfilterableActivityTypes,
  getActivityTypeForPage,
  getActivityMenuData,
  getActivityUrlForType,
  getPercentage,
  formatNumber,
  friendlyTypeNameFor,
  isActivityCloseable,
  isActivityClosed,
  isActivityDeletable,
  isActivityPublicizable,
  isActivityPublished,
  isActivityReopenable,
  isDisabled,
  isInitialScript,
  isTextingActivity,
  isRegistrationActivity,
  permitActivityParams,
  messages,
  statusColorFor,
  privacyColorFor,
  statusFor,
  getTooltipForTypePage,
  ACTIVITY_TYPES_WE_WANT_TO_SHOW,
  activityStatuses,
  reassignableActivityTypes,
  formatIncentives,
  f2fModesPermitted,
};
